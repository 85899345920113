import React, { useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './index.css';
import useGlitchEffect from '../../../hooks/useTextGlitch';

// Assuming these are the correct paths to your images
import Pareidolia from '../../../assets/images/Pareidolia.png';
import Bitscape from '../../../assets/images/Bitscape/Interface.png';
import Casino from '../../../assets/images/ChoctawNation/casino.jpg';


const WorkMenu = () => {
  const navigate = useNavigate();
  const isMobile = window.innerWidth <= 768; // This should ideally be managed via state and event listeners or using a library like react-responsive

  const projects = [
    { id: '001', name: "PAREIDOLIA", description: "Full-Stack Development", year: "2023", image: Pareidolia },
    { id: '002', name: "BITSCAPE", description: "Full-Stack Development", year: "2023-2024", image: Bitscape },
    { id: '003', name: "CHOCTAW NATION OF OKLAHOMA", description: "Digital Design", year: "2017-2023", image: Casino }
  ];

  const rowRefs = useRef(projects.map(() => React.createRef()));
  const nameRefs = useRef(projects.map(() => React.createRef()));
  const { startGlitchEffect, stopGlitchEffect } = useGlitchEffect(nameRefs.current);

  useEffect(() => {
    projects.forEach((_, index) => {
      const row = rowRefs.current[index].current;
      if (row) {
        const handleMouseEnter = () => {
          if (!isMobile) {
            startGlitchEffect(nameRefs.current[index].current);
          }
        };
        const handleMouseLeave = () => {
          if (!isMobile) {
            stopGlitchEffect(nameRefs.current[index].current);
          }
        };

        row.addEventListener('mouseenter', handleMouseEnter);
        row.addEventListener('mouseleave', handleMouseLeave);

        return () => {
          row.removeEventListener('mouseenter', handleMouseEnter);
          row.removeEventListener('mouseleave', handleMouseLeave);
        };
      }
    });
  }, [isMobile]); // Dependency on isMobile to re-attach events when it changes

  return (
    <div className="work-menu">
      {projects.map((project, index) => (
        <div className="project-row" key={project.id} ref={rowRefs.current[index]}
          onClick={() => navigate(`/work/${project.id}`)}>
          <div className='project-detail'>  
            <div className='project-title'>
              <p className="project-number">{project.id}</p>
              <div className='project-name-row'>
                <div className="image-container">
                  <img src={project.image} alt={project.name} className="project-preview-image" />
                </div>
                <span ref={nameRefs.current[index]} id={`project-name-${project.id}`} className="project-name">{project.name}</span>
              </div>
            </div>
            <div className='project-link-section'>
              <h4 className="project-year">{project.year}</h4>
              <span className="project-description">{project.description}</span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default WorkMenu;



