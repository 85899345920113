import React, { useEffect, useState } from 'react';
import './index.css'; 
import DigitalGlitch from '../../../components/DigitalGlitch';


const Header = () => {
    const latitude = 33.92812110258542;
    const longitude = -96.43893946687734;
    const [currentTime, setCurrentTime] = useState(new Date());
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0, random: Math.random() });
      const [isLoading, setIsLoading] = useState(true);
    


    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);
        

        const handleMouseMove = (event) => {
            setMousePosition({
                x: event.clientX,
                y: event.clientY,
                random: Math.random().toFixed(2)  // Generates a random number to two decimal places
            });
        };

        window.addEventListener('mousemove', handleMouseMove);

        return () => {
            clearInterval(intervalId);
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);



    return (
        <header>
            <div className='topContainer'>
                <div className='timeLocationInfo'>
                <div className='timeInfo'>
                        <h4>[ time ]</h4>
                        {currentTime.toLocaleString()}
                    </div>
                    <div className='currentLocation'>
                        <h4>[ location] </h4>
                        {latitude}<br/>
                        {longitude}
                    </div>
                    </div>
                <div className='description'>
                    <p>I'm Colton Tollett, a Full-Stack Developer and Graphic Designer with expertise in web development and digital design, currently advancing my HCI studies at Georgia Tech.</p>
                </div>
            </div>
            <div className='middleSection'>
            <div className='middleContainer'>
            <div className='nameInfo'>
                    <h1>COLTON//</h1>
                    <h1>_TOLLETT.</h1>
                    <ul>
                        <li>[1] Developer</li>  
                        <li>[2] Designer</li>
                    </ul>
                </div>
            </div>
            <div className='middleContainer-2'>
            <DigitalGlitch />
            </div>
        </div>
        <div className='line'></div>
            <div className='bottomContainer'>
              
                <div className='bottom-info'>
                    <div className='edition'>
                    <h4>[ edition ]</h4>
                    <ul>
                        <li>folio_24</li>
                    </ul>
                    </div>
                    <div className='roles'>
                        <h4>[ roles ]</h4>
                        <ul>
                            <li>UX / UI</li>
                            <li>Front_End / Full_Stack</li>
                  
                        </ul>
                    </div>
                </div>
            <div className='mouse-info'>
              <p>{mousePosition.x}, {mousePosition.y}, {mousePosition.random}</p>
            </div>
            </div>       
        </header>
    );
};

export default Header;

