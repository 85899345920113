import { useRef, useEffect, useCallback } from 'react';
import gsap from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const useScrollFixedReveal = ({ containerRef, languageRef, frameworksRef, devToolsRef, librariesRef, designToolsRef }) => {
    const mediaQuery = window.matchMedia('(min-width: 768px)');

    const resetStyles = () => {
        // Function to reset styles if animations are not to be applied
        const sections = [languageRef, frameworksRef, devToolsRef, librariesRef, designToolsRef];
        sections.forEach((sectionRef) => {
            if (sectionRef.current) {
                gsap.utils.toArray(sectionRef.current.children).forEach((child) => {
                    gsap.set(child, { autoAlpha: 1, y: 0 }); // Reset to visible and no transform
                });
            }
        });
    };

    const setupTriggers = useCallback(() => {
        if (!mediaQuery.matches) {
            resetStyles();
            return; // Skip setup if screen width is less than 768px
        }

        ScrollTrigger.getAll().forEach(trigger => trigger.kill());

        if (containerRef.current) {
            ScrollTrigger.create({
                trigger: containerRef.current,
                start: "top 0%",
                end: `+=${window.innerHeight * 4}`,
                pin: true,
                scrub: true,
            });
        }

        const sections = [languageRef, frameworksRef, devToolsRef, librariesRef, designToolsRef];
        sections.forEach((sectionRef, idx) => {
            if (sectionRef.current) {
                gsap.utils.toArray(sectionRef.current.children).forEach((child, index) => {
                    gsap.fromTo(child, {
                        autoAlpha: 0,
                        y: 50
                    }, {
                        autoAlpha: 1,
                        y: 0,
                        duration: 1,
                        stagger: 2,
                        ease: "power2.out",
                        scrollTrigger: {
                            trigger: containerRef.current,
                            start: `top ${20 - 5 * idx}%`,
                            end: `+=${500 * (index + 1)}`,
                    
                            scrub: true,
                            toggleActions: "play none none reverse"
                        }
                    });
                });
            }
        });
    }, [containerRef, languageRef, frameworksRef, devToolsRef, librariesRef, designToolsRef, mediaQuery.matches]);

    useEffect(() => {
        setupTriggers();
        const handleResize = () => {
            ScrollTrigger.getAll().forEach(trigger => trigger.kill());
            if (mediaQuery.matches) {
                setupTriggers();
            } else {
                resetStyles();
            }
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
            ScrollTrigger.getAll().forEach(trigger => trigger.kill());
            resetStyles();
        };
    }, [setupTriggers]);

    return null;
};

export default useScrollFixedReveal;










