import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';
import './index.css'; // Import the CSS file

const vertexShader = `
uniform float time;
uniform vec2 mouse;
void main() {
    vec3 transformed = position;
    
    // Calculate the distance from the mouse to the center
    float mouseDistance = length(mouse);
    float proximity = 1.0 - mouseDistance;  // Closer to the center, the less pronounced the effect

    // Control the frequency and amplitude based on proximity
    float freq = 3.0 + proximity * 15.0;    // Increase frequency for more pronounced movement
    float amp = proximity * 10.0;           // Stronger amplitude for more distortion

    // Calculate distortion based on mouse position
    float angleX = position.x * freq + time * 5.0;
    float angleY = position.y * freq + time * 5.0;

    // Smoothly blend the original position with the distorted one based on proximity
    transformed.x = mix(transformed.x, transformed.x + sin(angleX) * amp * mouse.x, 1.0 - proximity);
    transformed.y = mix(transformed.y, transformed.y + sin(angleY) * amp * mouse.y, 1.0 - proximity);
    transformed.z = mix(transformed.z, transformed.z + cos(angleY) * amp * mouse.y, 1.0 - proximity);

    gl_Position = projectionMatrix * modelViewMatrix * vec4(transformed, 1.0);
}
`;

const fragmentShader = `
void main() {
    gl_FragColor = vec4(1.0, 0.0, 0.0, 1.0); // Solid red color
}
`

const DigitalGlitch = () => {
    const mountRef = useRef(null);
    const mouse = useRef({ x: 0, y: 0 });
    const uniforms = useRef({
        time: { type: "f", value: 0 },
        mouse: { type: "v2", value: new THREE.Vector2(0, 0) }
    });

    useEffect(() => {
        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
        renderer.setSize(window.innerWidth, window.innerHeight);
        renderer.setClearColor(0x000000, 0); // Transparent background color
        mountRef.current.appendChild(renderer.domElement);
        camera.position.z = 150;

        const geometry = new THREE.IcosahedronGeometry(25, 8);
        const material = new THREE.ShaderMaterial({
            uniforms: uniforms.current,
            vertexShader,
            fragmentShader,
            wireframe: true
        });
        const mesh = new THREE.Mesh(geometry, material);
        scene.add(mesh);

        const clock = new THREE.Clock();

        function animate() {
            requestAnimationFrame(animate);
            uniforms.current.time.value = clock.getElapsedTime();
            mesh.rotation.x += 0.001;
            mesh.rotation.y += 0.002;
            renderer.render(scene, camera);
        }

        animate();

        function onMouseMove(event) {
            mouse.current.x = (event.clientX / window.innerWidth) * 2 - 1;
            mouse.current.y = -(event.clientY / window.innerHeight) * 2 + 1;
            uniforms.current.mouse.value.set(mouse.current.x, mouse.current.y);
        }

        window.addEventListener('mousemove', onMouseMove);

        function handleResize() {
            const width = window.innerWidth;
            const height = window.innerHeight;
            renderer.setSize(width, height);
            camera.aspect = width / height;
            camera.updateProjectionMatrix();
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('mousemove', onMouseMove);
            window.removeEventListener('resize', handleResize);
            renderer.dispose();
            scene.traverse((object) => {
                if (object.isMesh) {
                    object.geometry.dispose();
                    object.material.dispose();
                }
            });
        };
    }, []);

    return <div ref={mountRef} className="glitch-container" />;
}

export default DigitalGlitch;




